body, button, input, select, textarea {
    font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    margin: 0;
}
input, select{    
    -ms-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    -webkit-box-sizing:border-box; 
}
.header {
    background-color: #363636;
    color: #ffffff;
    padding: 30px;
}
.container {
    font-size: 1.5rem;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    background-color: #DBDBDB;
    margin-top: 40px;
    max-width: 800px;
}
.inputs-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    /* border-radius: 10px; */
}
.totals-container{
    background-color: #363636;
    color: #ffffff;
    text-align: center;
    padding: 25px;
    /* border-radius: 10px; */
}
.input {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.input div {
    margin-bottom: 5px;
}
.input input, select {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
}
